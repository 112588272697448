<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
      </v-row>

      <v-card class="mb-6">
        <v-toolbar flat dark dense :color="appColor">
          <v-toolbar-title>Edit {{ page.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip
            bottom
            v-if="
              hasPermission(
                'view-module-pagecontent-add-page',
                this.$route.params.id
              )
            "
          >
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                color="accent"
                class="mr-2"
                v-on="on"
                @click="$refs.itemDialog.open()"
              >
                <v-icon small>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Add New Item</span>
          </v-tooltip>
        </v-toolbar>
        <v-divider></v-divider>

        <div class="pa-5">
          <v-expansion-panels multiple focusable v-if="page.items.length > 0">
            <v-expansion-panel
              :color="appColor"
              v-for="(item, index) in page.items"
              :key="item.id"
            >
              <v-expansion-panel-title
                v-if="errors['items.' + index + '.content']"
              >
                <v-toolbar flat dark dense color="red">
                  <v-toolbar-title>
                    <div
                      v-for="error in errors['items.' + index + '.content']"
                      :key="error.id"
                    >
                      {{ error }}
                    </div></v-toolbar-title
                  >
                </v-toolbar>
              </v-expansion-panel-title>

              <v-expansion-panel-header>
                <v-row no-gutters>
                  <v-col cols="6">{{ item.label }} </v-col>
                  <v-col class="text-right pr-4"
                    >Last Updated: {{ item.formatted_dates.updated_at }} by
                    {{ item.updated_by_user.full_name }}
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pt-4">
                <v-text-field
                  :label="item.label"
                  outlined
                  v-if="item.type === 'text'"
                  v-model="page.items[index].content"
                ></v-text-field>
                <v-textarea
                  :label="item.label"
                  outlined
                  v-else-if="item.type === 'textarea'"
                  v-model="page.items[index].content"
                ></v-textarea>
                <tiptap
                  class="mb-5"
                  :label="item.label"
                  v-else-if="item.type === 'wysiwyg'"
                  v-model="page.items[index].content"
                />
                <ImageItem
                  v-else-if="item.type === 'image'"
                  :item="item"
                  :pageId="page.id"
                />
                <VideoItem
                  v-else-if="item.type === 'video'"
                  :item="item"
                  :pageId="page.id"
                />
                <v-btn
                  depressed
                  color="accent"
                  :loading="savingChanges"
                  v-if="
                    item.type === 'text' ||
                    item.type === 'textarea' ||
                    item.type === 'wysiwyg'
                  "
                  @click="saveChanges"
                  >Save Content</v-btn
                >
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <p v-else class="mb-0">
            There is nothing that can be edited on this page.
          </p>
        </div>
      </v-card>

      <ItemDialog ref="itemDialog" />
    </v-container>
  </div>
</template>

<script>
import ItemDialog from "./components/ItemDialog";
import ImageItem from "./components/ImageItem";
import VideoItem from "./components/VideoItem";
import Tiptap from "@/views/components/Tiptap.vue";

export default {
  components: {
    ImageItem,
    VideoItem,
    Tiptap,
    ItemDialog,
  },
  props: {
    appColor: {
      type: String,
    },
  },

  data() {
    return {
      breadcrumbs: [
        {
          text: "Page Content",
          disabled: false,
          exact: true,
          to: {
            name: "module-pagecontent",
          },
        },
      ],
      savingChanges: false,
      errors: {},
    };
  },

  created() {
    this.breadcrumbs.push({
      text: this.page.name,
      disabled: true,
    });
  },

  computed: {
    page() {
      return this.$store.state.pageContent["page"];
    },
  },

  methods: {
    saveChanges: function () {
      this.savingChanges = true;
      this.errors = {};

      let fields = {
        name: this.page.name,
        slug: this.page.slug,
        items: [],
      };

      this.page.items.forEach((item) => {
        fields.items.push({
          id: item.id,
          label: item.label,
          type: item.type,
          content: item.content,
        });
      });

      this.$store
        .dispatch("pageContent/savePage", {
          appId: this.$route.params.id,
          pageId: this.$route.params.pageId,
          isEditing: true,
          fields: {
            ...fields,
          },
        })
        .then(() => {
          this.savingChanges = false;
          this.$store.commit("showGlobalSnackbar", {
            open: true,
            text: "Page Successfully Saved",
          });
        })
        .catch((err) => {
          this.errors = err.response.data.errors;

          this.savingChanges = false;
          this.$store.commit("showGlobalSnackbar", {
            open: true,
            text: "Error! Save was Unsuccessful",
          });
        });
    },
  },
};
</script>
