<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">Add Item</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="item-form">
          <v-text-field
            label="Label"
            v-model="fields.label"
            outlined
            background-color="white"
          ></v-text-field>

          <!-- <v-text-field
            label="Name"
            v-model="fields.name"
            outlined
            background-color="white"
          ></v-text-field> -->

          <v-select
            label="Type"
            v-model="fields.type"
            :items="types"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('type')"
            :error-messages="errors['type']"
          ></v-select>

          <v-text-field
            label="Validation"
            v-model="fields.validation"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('validation')"
            :error-messages="errors['validation']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="item-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      page: {},
      fields: {
        name: null,
        slug: null,
        validation: null,
      },
      errors: {},

      types: [
        { text: "Text", value: "text" },
        { text: "Textarea", value: "textarea" },
        { text: "Wysiwyg", value: "wysiwyg" },
        { text: "Image", value: "image" },
        { text: "Video", value: "video" },
      ],
    };
  },

  computed: {
    slug: {
      get() {
        if (this.isEditing) {
          return this.fields.slug;
        }

        let slug = this.fields.name;

        if (slug === "" || slug === null) {
          return slug;
        }

        slug = slug.trim();
        slug = slug.replaceAll("&", "and");
        slug = slug.replaceAll(",", "");
        slug = slug.replaceAll(/[^a-zA-Z ]/g, "");
        slug = slug.replaceAll(" ", "-");
        slug = slug.toLowerCase();
        if (slug.substr(-1) === "-") {
          slug = slug.substr(0, slug.length - 1);
        }

        return slug;
      },
      set(value) {
        return value;
      },
    },
  },

  watch: {
    slug(value) {
      this.fields.slug = value;
    },
  },

  methods: {
    open(page = null) {
      if (page !== null) {
        this.page = page;
        this.isEditing = true;

        this.fields.name = page.name;
        this.fields.slug = page.slug;
        this.fields.validation = page.validation;
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        pageId: this.$route.params.pageId,
        isEditing: false,
        fields: this.fields,
      };

      this.$store
        .dispatch("pageContent/saveItem", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.page = {};
      this.fields = {
        name: null,
        slug: null,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
